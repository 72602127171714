@import "../_colors.scss";
@import "../_mixins.scss";
#viewCar {
  .view-car-info {
    @include txt-decoration($white, 3px);
    @include padding(1em 1.25em);
    width: 100%;
    margin-top: 1em;
    .heading {
      width: 100%;
      padding-bottom: 10px;
      .title {
        @include title(clamp(1rem, 1.25vw, 2.5rem), 150%, $blue);
      }
    }
    .viewtitle {
      @include title(clamp(0.725rem, 0.935vw, 2rem), 150%, $gray);
    }
    .details {
      @include title(clamp(0.725rem, 0.935vw, 2rem), 150%, $black);
    }
  }
}
