/*--Add page--*/

@mixin txt-decoration($white, $border-radius) {
  background: $white;
  border-radius: $border-radius;
}

@mixin txt-decoration1($blue, $line-height, $font-size) {
  color: $blue;
  line-height: $line-height;
  font-size: $font-size;
}

@mixin font($font-weight, $font-size, $line-height, $gray) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $gray;
}

@mixin title($font-size, $line-height, $darkblue) {
  font-size: $font-size;
  line-height: $line-height;
  color: $darkblue;
}

@mixin input-field($black, $font-size, $line-height) {
  color: $black;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin padding($values...) {
  @each $var in $values {
    padding: #{$var};
  }
}
