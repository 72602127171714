.custm-table-main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}
.custm-table-main .custm-th {
  color: #787885;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  /* text-transform: uppercase; */
}
.custm-table-main .MuiTableCell-root {
  /* padding: 7px 10px 7px 14px; */
  padding: 8px 10px 8px 14px;
}
.custm-loader {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 55%;
}

.cardList {
  padding: 15px;
  margin-top: 2em;
}

#salesRepresentative {
  padding: 2rem;
  background: #f2f2f2;
  min-height: 100vh;
}

.MuiTableRow-head {
  background: #fbfbfb;
}
