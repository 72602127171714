#signIn {
  min-height: 100vh;
  background: linear-gradient(282.14deg, #e6eefa 6.4%, rgba(200, 219, 245, 0) 90.62%), linear-gradient(0deg, #fff, #fff);
  background-color: #e6eefa;
  .logo {
    position: absolute;
    top: 2em;
    left: 2em;
    margin: 0;
  }
  .bg-image {
    max-height: 100vh;
    object-fit: cover;
    @media (min-width: 1900px) {
      min-height: 100vh;
    }
  }
  .form {
    width: 100%;
    max-width: 60%;

    .tagline {
      color: #787885;
      font-family: 'Plus Jakarta Sans', sans-serif !important;
      font-style: normal;
      font-size: 15px;
    }
    .MuiOutlinedInput-input {
      height: 1.1em;
    }
    .title {
      font-size: 2.5em;
      font-family: 'Plus Jakarta Sans', sans-serif !important;
      font-style: normal;
      font-weight: 500;
    }
  }
  .copyright-text {
    color: #787885;
    // font-size: 0.915em;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-style: normal;
    font-size: 12px;
  }
}
