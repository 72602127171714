@import "../_colors.scss";
@import "../_mixins.scss";
#viewUpload {
  .upload {
    @include txt-decoration($white, 3px);
    @include padding(1em 1.25em);
    margin-top: 1em;
    width: 100%;
    .title {
      @include title(clamp(1rem, 1.25vw, 2.5rem), 150%, $blue);
    }
    .icon-btn {
      float: left;
      color: black;
    }
    .arrow-down-button {
      float: right;
      color: #f80202;
    }
    .eye-icon {
      float: right;
      color: blue;
    }
    .block-inner {
      .address-proof {
        display: inline-flex;
        margin-left: 1em;
        .selective {
          height: 50%;
          width: 85%;
          background: #f6f7f9;
          border-radius: 5px;
        }
        .btn {
          background: #1976d2;
          color: #ffffff;
          padding: 12px 20px;
          border-radius: 5px;
          border: none;
          cursor: pointer;
        }
        .btn1 {
          background: silver;
          color: #ffffff;
          padding: 10px 20px;
          border-radius: 5px;
          border: none;
          cursor: pointer;
        }
        .uploader-block {
          margin-top: 23px;
          align-items: center;
          width: 441.12px;
          height: 45px;
          background: #f9f9f9;
          border-radius: 5px;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          @media (max-width:1130px) {
            width:-webkit-fill-available;
          }
          .delete {
            color: #f80202;
            cursor: pointer;
          }
        }
      }
    }
  }
}
