#Sales {
  padding: 4.5em;
  background: #f2f2f2;
  .sale-table-list {
    width: 100%;
    overflow: hidden;
    background: #fff;
    .custm-th {
      color: #787885;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
    }
    .table-data {
      font-size: 14px;
      line-height: 20px;
      color: #1a202c;
      .actions {
        font-size: 13px;
        color: #fff;
        background: #3563e9;
        border-radius: 28px;
        padding: 6px 8px;
        margin: 0 5px;
      }
      .graph {
        font-size: 13px;
        color: #fff;
        background: #15bc1c;
        border-radius: 28px;
        padding: 8px;
      }
    }
  }
}
