@import "../_colors.scss";
@import "../_mixins.scss";
#addOthers {
  .add-others {
    @include txt-decoration($white, 3px);
    @include padding(1em 1.25em);
    .block-inner {
      .block-item {
        .title {
          @include txt-decoration1($blue, 150%, clamp(.9rem, 1.25vw, 2.25rem) !important);
        }
      }
      .block-inner {
        .form-block {
          .form-inner {
            margin: 0px !important;
            width: calc(100% - 10px);
            .heading {
              @include title(clamp(.875rem, 1vw, 2rem), 150%, $darkblue);
              color: maroon;
            }
            .text-field {
              @include input-field($black, clamp(.875rem, 1vw, 2rem) !important, 150%);
              background: #f6f7f9;
              // border: 2px $lightgray;
              // border-radius: 10px;
              // border: 2px solid #d9e4f4;
            }
            .MuiInput-root:before {
              border-bottom: none !important;
            }
          }
        }
      }
    }
  }
}
