@import "../_colors.scss";
@import "../_mixins.scss";
#addBooking {
  .booking-info {
    @include txt-decoration($white, 3px);
    @include padding(1em 1.25em);
    .booking-title {
      @include txt-decoration1($blue, 150%, clamp(.9rem, 1.25vw, 2.25rem) !important);
    }
    .block-inner {
      .form-block {
        .form-inner {
          margin: 0px !important;
          width: calc(100% - 10px);
          .heading-1 {
            @include title(clamp(.875rem, 1vw, 2rem), 150%, $darkblue);
           
          }
          .text-field {
            @include input-field($black, clamp(.875rem, 1vw, 2rem) !important, 150%);
            background: #f6f7f9;
          }
          .MuiAutocomplete-root .MuiFilledInput-root {
            padding-top: 0px !important;
            background: $white1 !important;
          }
        }
      }
    }
    .select-radio {
      display: inline-flex;
      margin-top: 10px;
      gap: 1rem;
      .radio-group {
        display: flex;
        align-items: center;
        gap: .3125rem;
      }
      .radio-text {
        font-size: clamp(.88rem, 0.92vw, 1.875rem);
      }
    }
  }
}

// .addbooking-btn {
//   display: flex;
//   justify-content: flex-end;
//   margin-top: 2em;
//   .button {
//     display: flex;
//     justify-content: flex-end;
//     margin-top: 2em;
//     background-color: #1976d2;
//     color: #ffffff;
//     padding: 12px 20px;
//     border-radius: 5px;
//     border: none;
//   }
// }
