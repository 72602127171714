.image {
  max-width: 100%;
}

a {
  text-decoration: none;
}

fieldset {
  border: none !important;
  outline: none !important;
}

.css-w8dmq8 {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-style: normal !important;
}


// Alert Pop-up Styles
.swal2-title {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-weight: 300;
  letter-spacing: -0.05rem;
}
.swal2-styled.swal2-confirm {
  background-color: #ee0241!important;
  padding: .625em 2em;
  box-shadow: 0 0 0 3px #ee024130!important;
}
.swal2-icon.swal2-error {
  color: #ee0241!important;
  border-color: #ee0241!important;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line]{
  background-color: #ee0241!important;
}
.swal2-html-container {
  ol {
    height: 300px!important;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ee0241;
    }
    li {
      font-family: 'Plus Jakarta Sans', sans-serif !important;
      font-weight: 300;
      line-height: 1.7;
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400&display=swap');
