@import "../_colors.scss";
@import "../_mixins.scss";
#viewOtherInfo {
  .other-details {
    @include txt-decoration($white, 3px);
    @include padding(1em 1.25em);
    margin-top: 1em;
    width: 100%;
    .heading {
      width: 100%;
      .title {
        @include title(clamp(1rem, 1.25vw, 2.5rem), 150%, $blue);
      }
    }
    .other-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 25px;
      .viewtitle {
        @include title(clamp(0.725rem, 0.935vw, 2rem), 150%, $gray);
      }
      .details {
        @include title(clamp(0.725rem, 0.935vw, 2rem), 150%, $black);
      }
      .title-2 {
        font-size: 18px;
        color: black;
        text-decoration: underline;
      }
    }
  }
}
