@import "../_colors.scss";
@import "../_mixins.scss";
#addAccessories {
  .accessories-info {
    @include txt-decoration($white, 3px);
    @include padding(1em 1.25em);
    .block-inner {
      .block-item {
        .title {
          @include txt-decoration1($blue, 150%, clamp(.9rem, 1.12vw, 2.25rem));
        }
      }
      .form-block {
        .form-inner {
          margin: 0px !important;
          width: calc(100% - 10px);
          .heading-1 {
            @include title(clamp(.875rem, 1vw, 2rem), 150%, $darkblue);
          }
        }
      }
    }
  }
  .price-block {
    @include txt-decoration($white, 3px);
    @include padding(1em 1.25em);
    margin-top: 1em;
    .block-inner {
      .form-block {
        .form-inner {
          margin: 0px !important;
          width: calc(100% - 10px);
          .heading-1 {
            @include title(clamp(.875rem, 1vw, 2rem), 150%, $darkblue);
          }
          .text-field {
            @include input-field($black, clamp(.875rem, 1vw, 2rem) !important, 150%);
            // border: 2px $lightgray;
            background: #f6f7f9;
            // border-radius: 10px;
            // border: 2px solid #d9e4f4;
          }
          .MuiInput-root:before {
            border-bottom: none !important;
          }
        }
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: "textfield";
}
