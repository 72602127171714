#EditProfile {
  background: rgb(229, 229, 229);
  padding: 4em;
  .box {
    box-shadow: none;
    .card {
      background: #fff;
      padding: 2em;
      border-radius: 15px;
      .title {
        display: flex;
        justify-content: center;
      }

      .form-block {
        display: flex;
        justify-content: center;
        .button {
          background: #3563e9;
          border-radius: 5px;
          padding: 10px 15px;
          border: none;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
