.enquiry-page {
  width: 100%;
  padding: 1em;
}

#enquiryPage {
  width: 100%;
  min-height: 100vh;
  background: #f2f2f2;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination ul {
  justify-content: end;
}

.right-pagination {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 1rem;
  gap: 5px;
}

.custm-table-main {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}

.custm-table-main .custm-th {
  padding: 20px;
}

.table-head {
  padding: 0px 6px 0px 18px !important;
  height: 58px !important;
  white-space: nowrap;
}

.custm-table-main .MuiTableCell-root {
  white-space: nowrap;
  font-size: clamp(.875rem, 0.729vw, 1.75rem);
}

@media (min-width: 600px) and (max-width: 1448px) {
  .css-1ygcj2i-MuiTableCell-root {
    padding: 20px;
  }
}

.MuiButtonBase-root-MuiIconButton-root {
  padding: 0px !important;
}

.custm-loader {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 55%;
}

.find {
  padding: 10px 16px;
  background: #ffffff;
  border: none;
  border-radius: 3px;
}

.MuiInputBase-input {
  padding: 0.52vw 1.56vw 0.52vw 0.26vw !important;  /* border: 1px solid lightgrey !important; */
}
.MuiOutlinedInput-root {
  padding: 0px !important;
}
.MuiAutocomplete-root {
 height: inherit !important;
}
.MuiTableCell-root {
  font-size: clamp(.875rem, 0.729vw, 1.75rem) !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-style: normal;
}


.search {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
 justify-content: space-between;
}
 .search label {
  top: -7px;
  font-size: 14px !important;
 }
 