@import "../_colors.scss";
@import "../_mixins.scss";
#AddDMS {
  .enquiry-title {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0.5rem;
    @include txt-decoration1($darkblue, 150%, clamp(.9rem, 1.25vw, 2.25rem) !important);
  }
  .add-dms-block {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-style: normal;
    width: 100%;
    @include txt-decoration($white, 3px);
    @include padding(1em 1.25em);
    .block-inner {
      .form-block {
        .form-inner {
          margin: 0px !important;
          width: calc(100% - 10px);
          .MuiInputBase-input {
            padding: 9px 14px !important;
          }
          .title {
            @include title( clamp(.875rem, 1vw, 2rem), 150%,$darkblue);
          }
          .text-field {
            @include input-field($black, clamp(.875rem, 1vw, 2rem) !important, 150%);
            // border: 2px $lightgray;
            background: #f6f7f9;
            // border: 2px solid #d9e4f4;
          }
          .MuiInput-root:before {
            border-bottom: none !important;
          }
        }
      }
    }
  }
}
