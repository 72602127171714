body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --text-normal: "#787885";
  --text-light: "#90A3BF";
  --primary: "#3563e9";
  --cultered: "#F6F7F9";
  --secondary: "#1A202C";
  --warning: "#FF8329";
  --success: "#219653";
  --pink: "#FF64EF";
}

.swal-wide {
  width: 600px !important;
}
.swal-upload{
  width:300px !important;
  height: 100px !important;
  }