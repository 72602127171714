@import "../_colors.scss";
@import "../_mixins.scss";
#viewDMS {
  .dms-info {
    position: fixed;
    top: 72px;
    right: 0;
    left: 275px;
    z-index: 99;
    padding: 4px 0.4em;
    background: rgb(42 73 123);
    color: #fff;
    border-radius: 0;
    .block-inner {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size:clamp(.875rem, 1vw, 2rem);
        .details {
          color: #ffffff;
          padding-left: 10px;
          font-size:clamp(.875rem, 1vw, 2rem);
          line-height: 150%;
        }
      }
    }
  }
}

#viewEnquiry {
  background-color:#F2F2F2;
  padding: 2.75rem 1.25em 2rem;
}
