#UpdatePassword {
  display: flex;
  justify-content: center;
  background: rgb(229, 229, 229);
  .update-inner-block {
    display: flex;
    justify-content: center;
    box-shadow: none;
    margin: 4em 0;
    .card-block {
      display: inline-block;
      justify-content: center;
      border-radius: 10px;
      width: 60vh;
      height: 40vh;
      padding: 1em;
      background-color: #ffffff;
      .card-inner {
        display: flex;
        justify-content: center;
      }
    }
  }
}
