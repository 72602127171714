#BaseLayout {
  margin-top: 72px;
  .top-bar {
    background: #ffffff;
    padding: 0.625rem 1rem;
  }
  .MuiAppBar-root {
    box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1);
  }
  .menu-list {
    padding: 20px 25px;
    margin-bottom: 0;
    color: rgba(148, 167, 203, 0.4);
  }
  .MuiList-root {
    margin: 10px 8px;
  }
  .userDropdown {
  }
  .Mui-selected {
    background: #3563e9;
    border-radius: 10px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.02em;
    color: #ffffff;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-style: normal;
    font-size: 16px;
    text-decoration: none;
    padding: 12x 18px;
  }
  .side-menu-bar > a:active {
    color: black;
  }
  .right-side-block {
    display: flex;
    align-items: center;
    column-gap: 1em;
    #basic-button {
      column-gap: 15px;
    }
  }
  .side-menu-bar > a.Mui-selected:hover {
    background: #1d45bb !important;
    color: #ffffff !important;
  }
  .MuiTypography-root {
    font-family: "Plus Jakarta Sans", sans-serif !important;
    font-style: normal;
    font-size: clamp(0.935rem, 0.95vw, 2.5rem);
  }
  .MuiListItemIcon-root {
    min-width: 35px !important;
  }
  .MuiListItemIcon-root:active {
    min-width: 35px !important;
  }
  .MuiListItemIcon-root {
    color: #ffffff !important;
  }
  .MuiListItemIcon-root svg {
    fill: #76849a;
  }
  .active {
    svg {
      fill: #fff;
    }
  }
  .list-item {
    color: #76849a;
  }
  .active {
    .list-item {
      color: #ffffff !important;
    }
  }
  .basicMenu {
    a {
      color: #000000;
    }
  }
  a {
    text-decoration: none;
  }
  .side-menu-bar > a {
    padding: -1px 15px;
    border-radius: 10px;
    .list-btn {
      // width: 254px;
      height: 56px;
      @media (min-width: 1920px) {
        margin-bottom: 1rem;
      }
    }
  }
}
