#UserProfile {
  padding: 3em;
  background: rgb(229, 229, 229);
  .block-1 {
    display: flex;
    justify-content: center;
    .inner {
      background: #fff;
      padding: 3em;
      border-radius: 14px;
      .button {
        background: #f44336;
        border-radius: 5px;
        padding: 10px 15px;
        border: none;
        cursor: pointer;
        color: #fff;
      }
    }
  }
}
