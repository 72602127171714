#CarList {
  padding: 3em 1em;
  background: #f2f2f2;
  min-height: 100vh;
  @media (min-width:1600px) {
    padding: 3em;
  }
  .action {
    float: right;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .table-data {
    width: 100%;
    overflow: hidden;
    background: #fff;
    .custm-th {
      color: #787885;
      font-size: clamp(.75rem, 0.75vw, 1.5rem) !important;
      line-height: 16px;
      text-transform: uppercase;
      font-weight: 500;
    }
    .table-lst {
      font-size: clamp(.875rem, 0.875vw, 1.75rem) !important;
      line-height: 20px;
      color: #1a202c;
    }
  }
}
#addNew-carPage {
  padding: 5em 1em;
  background: #f2f2f2;
  min-height: 100vh;
  .container {
    background-color: white;
    padding: 1em;
  }
  .form-container {
    margin-top: 1rem;
    .form-control {
      width: 90%;
    }
  }
  .button-box {
    display: flex;
    justify-content: end;
  }
  .submit-btn {
    margin-top: 1rem;
    margin-right: 2rem;
  }
}
